import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import Spiner from "components/Spiner"

export default function Loader({ visible, className }) {
  return (
    <AnimatePresence>
      {visible && (
        <Holder
          className={className}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container>
            <Spiner size={48} />
          </Container>
        </Holder>
      )}
    </AnimatePresence>
  )
}

const Holder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: #2d2d2d33;
`

const Container = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  transform: translate(-50%, -50%);
  padding: 0.5em;
`
