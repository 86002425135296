import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import translationEN from "./en.json"
import translationAR from "./ar.json"

export const LanguageOptions = [
  {
    code: "en",
    label: "English"
  },
  {
    code: "ar",
    label: "عربي"
  }
]

const langs = []
LanguageOptions.forEach(lang => {
  langs.push(lang.code)
})

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: langs,
    detection: {
      checkWhitelist: true
    },
    debug: false,
    whitelist: langs,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
