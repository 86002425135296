import html2canvas from "html2canvas"
import pdfMake from "pdfmake"

export async function downloadImage(ref) {
  if (ref) {
    const logoUrl =
      window.location.protocol + "//" + window.location.host + "/c4ads-logo.png"
    const fontUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      "/fonts/century_gothic.ttf"

    await html2canvas(ref, {
      dpi: 300,
      logging: true
    }).then(canvas => {
      const image = canvas.toDataURL("image/jpeg", 1.0)
      if (image) {
        pdfMake.fonts = {
          centuryGothic: {
            normal: fontUrl,
            bold: fontUrl
          }
        }
        const pdfDesc = {
          content: [
            {
              image: image,
              width: 480,
              relativePosition: {
                x: 0,
                y: 30
              },
              align: "right"
            },
            {
              image: "logo",
              width: 100,
              relativePosition: { x: 10, y: 0 }
              //margin: [30,30,0,0],
            }
          ],
          defaultStyle: {
            font: "centuryGothic"
          },
          styles: {
            legend: {
              alignment: "center"
            },
            chart: {
              alignment: "center"
            }
          },
          images: {
            logo: logoUrl
          }
        }

        pdfMake.createPdf(pdfDesc).download("c4ads-data-export.pdf")
      }
    })
  }
}
