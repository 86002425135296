import styled from "styled-components"

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`
export default Content
