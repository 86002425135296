import React from "react"
import styled from "styled-components"

import Graph from "./Graph"
import Sidebar from "./Sidebar"

export default function NetworkGraph() {
  return (
    <Holder>
      <Graph />
      <Sidebar />
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
`
