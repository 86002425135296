import Login from "pages/Login"
import Networkmap from "pages/Networkmap"

export const PrimaryRoutes = [
  // {
  //   label: "Login",
  //   path: "/login",
  //   component: <Login />
  // },
  {
    label: "Network Map",
    path: "/",
    component: <Networkmap />
  }
]
