import { GraphData } from "components/NetworkGraph/dataset/graph"
import { LanguageOptions } from "i8lang"
import create from "zustand"

export const useStore = create((set, get) => ({
  //Menu
  menuVisible: false,
  setMenuVisible: v => set({ menuVisible: v }),
  //Networkmap
  selectedNodeId: null,
  setSelectedNodeId: v => set({ selectedNodeId: v }),
  graphData: GraphData,
  setGraphData: v => set({ graphData: v }),
  currentLanguage: LanguageOptions[0].code,
  setCurrentLanguage: v => set({ currentLanguage: v }),
  //Toolbar
  selectedNodes: [],
  setSelectedNodes: v => set({ selectedNodes: v })
}))
