import { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "primereact/button"
import Clipboard from "react-clipboard.js"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

import { useStore } from "state/store"
import { device } from "theme/device"
import { LinkData, NodeData } from "../dataset/data"

import { downloadImage } from "utils/downloadImage"
import { useMemo } from "react"
import { LanguageOptions } from "i8lang"
import { SelectButton } from "primereact/selectbutton"
import i18next from "i18next"
import { GraphData } from "../dataset/graph"

function DetailTable({ nodeData, lang }) {
  const { t } = useTranslation()

  const flexDirection = useMemo(() => {
    return lang === "ar" ? "row-reverse" : "row"
  }, [lang])

  return (
    <Table>
      <TableRow style={{ flexDirection: flexDirection }}>
        <TableKey>{t("name")}</TableKey>
        <TableValue>
          {nodeData[lang === "en" ? "label_en" : "label_ar"]}
        </TableValue>
      </TableRow>
      <TableRow style={{ flexDirection: flexDirection }}>
        <TableKey>{t("type")}</TableKey>
        <TableValue>{t(nodeData["type"])}</TableValue>
      </TableRow>
      <TableRow style={{ flexDirection: flexDirection }}>
        <TableKey>{t("Registration Number")}</TableKey>
        <TableValue>{t(nodeData["Registration_Number"])}</TableValue>
      </TableRow>
      <TableRow style={{ flexDirection: flexDirection }}>
        <TableKey>{t("Registration Date")}</TableKey>
        <TableValue>{t(nodeData["Registration_Date"])}</TableValue>
      </TableRow>
    </Table>
  )
}

function RelatedEntities({ data, lang }) {
  const { t } = useTranslation()
  const selectedNodeId = useStore(state => state.selectedNodeId)
  const setSelectedNodeId = useStore(state => state.setSelectedNodeId)

  const newData = useMemo(() => {
    let d = {}
    Object.keys(data).forEach(key => {
      data[key].forEach(node => {
        if (
          LinkData.findIndex(
            link => link.child === selectedNodeId && link.parent === node.id
          ) !== -1
        ) {
          const newKey = key.replace("Shareholder of", "Owned by")
          if (!d[newKey]) {
            d[newKey] = []
          }

          if (d[newKey].findIndex(n => n.id === node.id) === -1) {
            d[newKey].push(node)
          }
        }

        if (
          LinkData.findIndex(
            link => link.parent === selectedNodeId && link.child === node.id
          ) !== -1
        ) {
          if (!d[key]) {
            d[key] = []
          }

          if (d[key].findIndex(n => n.id === node.id) === -1) {
            d[key].push(node)
          }
        }
      })
    })
    return d
  }, [data])

  return Object.keys(newData).map((key, index) => {
    let subTitle = key
    if (lang === "ar") {
      let link = LinkData.find(link => link.type === key)
      if (link) {
        subTitle = link.type_ar
      } else {
        const newKey = key.replace("Owned by", "Shareholder of")
        link = LinkData.find(link => link.type === newKey)
        if (link) {
          subTitle = link.type_ar
        }
      }
    }

    return (
      <div key={index}>
        <TableSubTitle>{subTitle}</TableSubTitle>
        <Table>
          {newData[key].map((item, i) => (
            <TableRow key={i} style={{ padding: "0 1em" }}>
              <RelatedEntityName
                onClick={() => {
                  setSelectedNodeId(item["id"])
                }}
              >
                {item["label"]}
              </RelatedEntityName>
            </TableRow>
          ))}
        </Table>
      </div>
    )
  })
}

export function SidebarContent({ nodeData, relatedData, style, lang }) {
  const { t } = useTranslation()
  const label = lang === "en" ? "label_en" : "label_ar"

  const flexDirection = useMemo(() => {
    return lang === "ar" ? "row-reverse" : "row"
  }, [lang])

  return (
    <Content style={style}>
      <Header style={{ flexDirection: flexDirection }}>
        <img src={nodeData["icon"]} alt="" />
        <h1>{nodeData[label]}</h1>
      </Header>
      <TableTitle>{t("details")}</TableTitle>
      <DetailTable nodeData={nodeData} lang={lang} />
      {relatedData !== {} ? (
        <>
          <TableTitle>{t("related_entities")}</TableTitle>
          <RelatedEntities data={relatedData} lang={lang} />
        </>
      ) : (
        <></>
      )}
    </Content>
  )
}

export default function Sidebar() {
  const { t } = useTranslation()
  const selectedNodeId = useStore(state => state.selectedNodeId)
  const setSelectedNodeId = useStore(state => state.setSelectedNodeId)

  const selectedNodes = useStore(state => state.selectedNodes)
  const setSelectedNodes = useStore(state => state.setSelectedNodes)

  const currentLanguage = useStore(state => state.currentLanguage)

  const [lang, setLang] = useState(currentLanguage)

  const textAlign = useMemo(() => {
    return lang === "ar" ? "right" : "left"
  }, [lang])

  useEffect(() => {
    setLang(currentLanguage)
  }, [currentLanguage])

  const [nodeData, setNodeData] = useState(null)
  const [relatedData, setRelatedData] = useState({})

  const ref = useRef(null)

  const handleInfoClose = e => {
    setSelectedNodeId(null)
  }

  const handleShare = e => {
    toast("Copied to clipboard!")
  }

  const handlePrint = e => {
    e.stopPropagation()
    downloadImage(ref.current)
  }

  const handleAddEntity = e => {
    const node = GraphData.nodes.find(node => node.code === selectedNodeId)
    setSelectedNodes([...selectedNodes, node])
  }

  const handleOpenlink = e => {
    const url = `${window.location.origin}/share?entity=${nodeData["id"]}&language=${lang}&node=${selectedNodeId}`
    window.open(url, "_blank")
  }

  const generateLink = () => {
    const url = `${window.location.origin}/share?entity=${nodeData["id"]}&language=${lang}&node=${selectedNodeId}`
    return url
  }

  useEffect(() => {
    // Node data
    const newNodeData = NodeData?.find(d => d["id"] === selectedNodeId)
    setNodeData(newNodeData)

    // Related data
    const newRelatedData = {}
    const newLinkData = LinkData?.filter(
      d => d["parent"] === selectedNodeId || d["child"] === selectedNodeId
    )

    newLinkData.forEach(d => {
      const id = d["parent"] === selectedNodeId ? d["child"] : d["parent"]
      const label = NodeData.find(d => d["id"] === id)[
        lang === "en" ? "label_en" : "label_ar"
      ]

      if (d["type"] in newRelatedData) {
        newRelatedData[d["type"]].push({
          id: id,
          label: label,
          text: d["text"]
        })
      } else {
        newRelatedData[d["type"]] = [
          {
            id: id,
            label: label,
            text: d["text"]
          }
        ]
      }
    })

    setRelatedData(newRelatedData)
  }, [selectedNodeId, lang])

  return (
    <AnimatePresence>
      {nodeData && (
        <Holder
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.8 }}
        >
          <Controls>
            <SelectButton
              optionValue="code"
              value={lang}
              options={LanguageOptions}
              onChange={e => {
                setLang(e.value)
                i18next.changeLanguage(e.value)
              }}
            />
            <AddEntityButton
              icon="pi pi-arrow-down-left"
              className="p-button-rounded p-button-text"
              onClick={handleAddEntity}
            />
            <OpenlinkButton
              icon="pi pi-external-link"
              className="p-button-rounded p-button-text"
              onClick={handleOpenlink}
            />
            <PrintButton
              icon="pi pi-print"
              className="p-button-rounded p-button-text"
              onClick={handlePrint}
            />
            <Clipboard
              component="a"
              button-href="#"
              onClick={e => e.preventDefault()}
              data-clipboard-text={generateLink()}
              onSuccess={handleShare}
              button-title=""
            >
              <ShareButton
                icon="pi pi-copy"
                className="p-button-rounded p-button-text"
              />
            </Clipboard>
            <CloseButton
              icon="pi pi-times"
              className="p-button-rounded p-button-text"
              onClick={handleInfoClose}
            />
          </Controls>
          <SidebarContent
            style={{ textAlign: textAlign }}
            nodeData={nodeData}
            relatedData={relatedData}
            lang={lang}
          />
          <PrintContent ref={ref}>
            <SidebarContent
              style={{ textAlign: textAlign }}
              nodeData={nodeData}
              relatedData={relatedData}
              lang={lang}
            />
          </PrintContent>
        </Holder>
      )}
    </AnimatePresence>
  )
}

const Holder = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 30em;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  @media ${device.mobileL} {
    width: 100%;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3em 2em 1em 2em;
  gap: 1.3em;
  font-size: 5px;
`

const CloseButton = styled(Button)`
  width: 3em !important;
  height: 3em !important;
  padding: 0;
  > span {
    font-size: 1.5em;
  }
`

const ShareButton = styled(Button)`
  width: 3em !important;
  height: 3em !important;
  padding: 0;
  > span {
    font-size: 1.5em;
  }
`

const PrintButton = styled(Button)`
  width: 3em !important;
  height: 3em !important;
  padding: 0;
  > span {
    font-size: 1.5em;
  }
`

const OpenlinkButton = styled(Button)`
  width: 3em !important;
  height: 3em !important;
  padding: 0;
  > span {
    font-size: 1.5em;
  }
`

const AddEntityButton = styled(Button)`
  width: 3em !important;
  height: 3em !important;
  padding: 0;
  > span {
    font-size: 1.5em;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  img {
    width: 5em;
    height: 5em;
    border-radius: 50%;
  }
  h1 {
    flex: 1;
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1.1;
    color: #545355;
    margin: 0;
    padding: 0;
  }
`

const TableTitle = styled.div`
  width: 100%;
  padding: 0.5em;
  font-size: 1.1em;
  background-color: #333;
  color: white;
  margin: 0.7em 0;
`

const TableSubTitle = styled.div`
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  color: #333;
  margin: 0.7em 0;
  background-color: #e2e1e1;
`

const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const TableKey = styled.div`
  width: 50%;
  padding-left: 0.5em;
`

const TableValue = styled.div`
  width: 50%;
  word-break: break-all;
  p {
    padding: 0;
    margin: 0;
    line-height: 1.2;
    margin-bottom: 0.5em;
  }
`

const RelatedEntityName = styled.div`
  width: 100%;
  word-break: break-all;
  font-size: 0.9em;
  color: #2859a9;
  text-decoration: underline;
  cursor: pointer;
`

const PrintContent = styled.div`
  position: fixed;
  top: 0;
  left: 6000px;
  width: 900px;
`
