import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle
} from "styled-components"
import Div100vh from "react-div-100vh"
import { isMobile } from "react-device-detect"
import { normalize } from "polished"

import theme from "./theme"

//Load primereact styles
import "theme/lara-light-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import PrimeReact from "primereact/api"
PrimeReact.ripple = true
PrimeReact.inputStyle = "filled"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: ${theme.colors.primary};
    font-Size: 16px;
    font-family: "centry_gothic";
    padding: 0;
    margin: 0;
    touch-action: pan-x pan-y;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;    
  }

  *::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
    border-radius: 0px;
  }
  *::-webkit-scrollbar-thumb {
    height: 92px;
    background-color: #9b9b9b;
    border-radius: 0;
  }

`

const MobileContainer = styled(Div100vh)`
  width: 100vw;
`

const DesktopContainer = styled.div`
  width: calc(100vw - 7px);
  height: 100vh;
`

export default function ThemeProvider({ children }) {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      {isMobile ? (
        <MobileContainer> {children} </MobileContainer>
      ) : (
        <DesktopContainer> {children} </DesktopContainer>
      )}
    </StyledComponentsThemeProvider>
  )
}
