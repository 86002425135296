//add comma separator to number
export function addCommaToNumber(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return ""
}

export function getUrlParams() {
  var result = {}
  var params = (window.location.search.split("?")[1] || "").split("&")
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      var paramParts = params[param].split("=")
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "")
    }
  }
  return result
}

export const getQueryString = (search = window.location.search, name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
  let r = search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export function roundNum(num) {
  return Math.round((num + Number.EPSILON) * 10) / 10
}

export function CompareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase()
  b = b.toLowerCase()

  return a < b ? -1 : a > b ? 1 : 0
}
