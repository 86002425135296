import { useEffect, useRef } from "react"
import styled from "styled-components"
import i18next from "i18next"
import { MultiSelect } from "primereact/multiselect"
import { SelectButton } from "primereact/selectbutton"
import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { useStore } from "state/store"
import { device } from "theme/device"
import { GraphData } from "components/NetworkGraph/dataset/graph"
import { LanguageOptions } from "i8lang"

export default function Toolbar() {
  const selectedNodes = useStore(state => state.selectedNodes)
  const setSelectedNodes = useStore(state => state.setSelectedNodes)
  const setGraphData = useStore(state => state.setGraphData)
  const currentLanguage = useStore(state => state.currentLanguage)
  const setCurrentLanguage = useStore(state => state.setCurrentLanguage)

  const overlayPanelRef = useRef(null)
  const textAlign = currentLanguage === "ar" ? "right" : "left"

  const itemTemplate = option => {
    return (
      <div style={{ textAlign: textAlign }}>
        {currentLanguage === "en" ? option.data.label_en : option.data.label_ar}
      </div>
    )
  }

  const selectedItemTemplate = option => {
    if (option) {
      return (
        <div style={{ textAlign: textAlign }}>
          {currentLanguage === "en"
            ? option.data.label_en
            : option.data.label_ar}
        </div>
      )
    }
    return "Select SCEs"
  }

  const onChange = event => {
    setSelectedNodes(event.value)
  }

  useEffect(() => {
    const InitSelectedNode = GraphData.nodes.find(
      node => node.name_en === "OMDURMAN NATIONAL BANK"
    )
    setSelectedNodes([InitSelectedNode])
  }, [])

  useEffect(() => {
    const newGraphData = {
      nodes: [],
      edges: []
    }

    if (selectedNodes.length === 0) {
      //No selected
      newGraphData.nodes = []
      newGraphData.edges = []
    } else {
      //1st relationship
      const data1 = {
        nodes: [],
        edges: []
      }
      GraphData.edges.forEach(edge => {
        if (
          selectedNodes.findIndex(node => node.data.id === edge.data.source) !==
            -1 ||
          selectedNodes.findIndex(node => node.data.id === edge.data.target) !==
            -1
        ) {
          if (
            data1.edges.findIndex(
              e =>
                e.data.source === edge.data.source &&
                e.data.target === edge.data.target
            ) === -1
          ) {
            data1.edges.push(edge)
          }

          //Add new nodes
          const sourceNode = GraphData.nodes.find(
            node => node.data.id === edge.data.source
          )
          if (
            data1.nodes.findIndex(
              node => node.data.id === sourceNode.data.id
            ) === -1
          ) {
            data1.nodes.push(sourceNode)
          }

          const targetNode = GraphData.nodes.find(
            node => node.data.id === edge.data.target
          )
          if (
            data1.nodes.findIndex(
              node => node.data.id === targetNode.data.id
            ) === -1
          ) {
            data1.nodes.push(targetNode)
          }
        }
      })

      //2st relationship
      const data2 = {
        nodes: [],
        edges: []
      }
      GraphData.edges.forEach(edge => {
        if (
          data1.nodes.findIndex(node => node.data.id === edge.data.source) !==
            -1 ||
          data1.nodes.findIndex(node => node.data.id === edge.data.target) !==
            -1
        ) {
          if (
            data2.edges.findIndex(
              e =>
                e.data.source === edge.data.source &&
                e.data.target === edge.data.target
            ) === -1
          ) {
            data2.edges.push(edge)
          }

          //Add new nodes
          const sourceNode = GraphData.nodes.find(
            node => node.data.id === edge.data.source
          )
          if (
            data2.nodes.findIndex(
              node => node.data.id === sourceNode.data.id
            ) === -1
          ) {
            data2.nodes.push(sourceNode)
          }

          const targetNode = GraphData.nodes.find(
            node => node.data.id === edge.data.target
          )
          if (
            data2.nodes.findIndex(
              node => node.data.id === targetNode.data.id
            ) === -1
          ) {
            data2.nodes.push(targetNode)
          }
        }
      })

      //Assign
      newGraphData.nodes = data2.nodes
      newGraphData.edges = data2.edges
    }

    setGraphData(newGraphData)
  }, [selectedNodes])

  return (
    <Holder>
      <Button
        onClick={e => {
          overlayPanelRef.current.toggle(e)
        }}
        icon="pi pi-question"
        className="p-button-rounded p-button-outlined"
      ></Button>
      <SelectButton
        optionValue="code"
        value={currentLanguage}
        options={LanguageOptions}
        onChange={e => {
          setCurrentLanguage(e.value)
          i18next.changeLanguage(e.value)
        }}
      />
      <StyledMultiSelect
        value={selectedNodes}
        options={GraphData.nodes}
        onChange={onChange}
        optionLabel={`name_${currentLanguage}`}
        placeholder="Select SCEs"
        filter
        itemTemplate={itemTemplate}
        selectedItemTemplate={selectedItemTemplate}
        scrollHeight="500px"
        maxSelectedLabels={1}
      />
      <OverlayPanel ref={overlayPanelRef}>
        <Disclaimer>
          {currentLanguage === "ar" ? (
            <>
              إن اختيار هيئة ما يمتد تلقائيًا ليشمل جميع الهيئات ضمن مستويين من
              الصلات مع تلك الهيئة، واختيار هيئات أخرى سوف يضيفها للشكل
              البياني. في حال كون الهيئات تمتلك أقل من 0.01% من أسهم الشركة، فمن
              الممكن وصف الملكية بأنها 0%. يستخدم مركز سي فور اي دي اس سجلات
              الشركات الرسمية حيثما كان ذلك ممك ًنا للتحقق من شبكات ملكية
              الشركة. ومع ذلك، لا توفر هذه المعلومات سوى لمحة خاطفة عن نشاط
              الشركة في وقت معين: قد لا يتم تحديث السجلات بشكل منتظم، وقد لا
              تكون متسقة أو دقيقة تما ًما، وقد لا تتمتع بنفس معايير الإبلاغ عبر
              الاختصاصات القضائية. بالإضافة إلى ذلك، لا تكشف السجلات العامة عن
              جميع تفاصيل عمليات الشركة أو علاقاتها مع الكيانات الأخرى. لذلك،
              يعمل مركز C4ADS على حصر استنتاجاته التحليلية على تلك المدعومة
              بالوثائق الأساسية بشكل مباشر. ما لم يتم ذكره صراحة، فإن ذكر أي
              شخص، أو شركة، أو منظمة، أو كيان آخر في هذا التقرير لا ُيقصد منه
              الإشارة الضمنية لانتهاك أي قانون أو اتفاقية دولية. لا تشمل بيانات
              سي فور اي دي اس كل مؤسسات خاضعة لسيطرة الدولة، وهناك مئات من
              المؤسسات خاضعة لسيطرة الدولة ما زالت مجهولة. لو عندك معلومات عن
              مؤسسات خاضعة لسيطرة{" "}
              <a
                href="https://c4ads.org/tips"
                target="_blank"
                rel="noopener noreferrer"
              >
                معنا هنا
              </a>{" "}
              الدولة اضافية، نشجعك ان تشارك
            </>
          ) : (
            <>
              Selection of an entity automatically expands to all entities
              within two degrees of connection to that entity; selecting
              additional entities will add them to the graph. In cases where
              entities own less than 0.01% of a company’s shares, ownership may
              be depicted as 0%.
              <br />
              C4ADS uses official corporate records whenever possible to verify
              corporate ownership networks. However, this information represents
              only a snapshot of corporate activity at a given time; records may
              not be updated regularly, may not be consistent or wholly
              accurate, and may not have the same reporting standards across
              jurisdictions. In addition, public records do not reveal all
              details of a company’s operations or relationships between
              entities. Therefore, C4ADS limits its analytical conclusions to
              those supported directly by underlying documentation. Unless
              explicitly stated, the mention of an individual, company,
              organization, or other entity in this report is not meant to imply
              the violation of any law or international agreement.
              <br />
              C4ADS data is not comprehensive of the vast landscape of Sudanese
              state-owned enterprises, and many hundreds of state-owned
              enterprises likely remain unidentified. If you have documentation
              of additional state-owned enterprises, we encourage you to share
              it with{" "}
              <a
                href="https://c4ads.org/tips"
                target="_blank"
                rel="noopener noreferrer"
              >
                C4ADS
              </a>
            </>
          )}
        </Disclaimer>
      </OverlayPanel>
    </Holder>
  )
}

const Holder = styled.div`
  width: 100%;
  padding: 0.5em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1em;
`

const StyledMultiSelect = styled(MultiSelect)`
  width: 25em;
`

const Disclaimer = styled.div`
  max-width: 30em;
  font-size: 1em;
  line-height: 1.5;
  color: #212121;
  text-align: justify;
  @media ${device.mobileL} {
    font-size: 0.8em;
  }
`
