import OriginData from "dataset/real-data.json"

const LinkTypes = []
const NodeTypes = []

OriginData.links.forEach(link => {
  if (!LinkTypes.includes(link.type)) {
    LinkTypes.push(link.type)
  }
})

OriginData.nodes.forEach(node => {
  if (!NodeTypes.includes(node.type)) {
    NodeTypes.push(node.type)
  }
})

//Set icons
OriginData.nodes.forEach(node => {
  node.icon = "/images/Empty.svg"
  node.icon_size = 60

  if (node.type === "Company") {
    node.icon = "/images/Company.svg"
    node.icon_size = 80
  } else if (node.type === "Person") {
    node.icon = "/images/Person.svg"
    node.icon_size = 40
  } else if (node.type === "Foundation") {
    node.icon = "/images/Charity.svg"
    node.icon_size = 80
  } else if (node.type === "Government Organization") {
    node.icon = "/images/Goverment.svg"
    node.icon_size = 200
  } else if (node.type === "Bank") {
    node.icon = "/images/Bank.svg"
    node.icon_size = 100
  } else if (node.type === "SCE") {
    node.icon = "/images/SCE.svg"
    node.icon_size = 150
  } else if (node.type === "Non-Profit") {
    node.icon = "/images/Charity.svg"
    node.icon_size = 120
  } else if (node.type === "RSF") {
    node.icon = "/images/RSF.svg"
    node.icon_size = 100
  } else if (node.type === "SAF") {
    node.icon = "/images/SAF.svg"
    node.icon_size = 100
  }
})

const NodeData = Array.from(OriginData.nodes)
const LinkData = Array.from(OriginData.links)

export { NodeData, LinkData, LinkTypes, NodeTypes }
