import { useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useMedia } from "react-media"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import { Button } from "primereact/button"

import { PrimaryRoutes } from "dataset/routes"
import { device } from "theme/device"
import { useStore } from "state/store"
import NavMenuButton from "components/NavMenuButton"
import LogoIcon from "assets/images/logo.svg"

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const isPadScreen = useMedia({ query: device.pad })
  const menuVisible = useStore(state => state.menuVisible)
  const setMenuVisible = useStore(state => state.setMenuVisible)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Holder>
      <LogoSection>
        <Logo href="https://c4ads.org/" target="_blank" rel="noreferrer">
          <img alt="logo" src={LogoIcon} />
        </Logo>
      </LogoSection>
      {isPadScreen ? (
        <>
          <NavMenuButton />
          <AnimatePresence>
            {menuVisible && (
              <NavMobile
                initial="hidden"
                animate={menuVisible ? "visible" : "hidden"}
                exit="hidden"
                variants={{
                  visible: {
                    x: 0,
                    transition: {
                      ease: [0.0, 0.0, 0.21, 0.89],
                      when: "beforeChildren",
                      staggerChildren: 0.15
                    }
                  },
                  hidden: {
                    x: "-100%",
                    transition: {
                      ease: [0.0, 0.0, 0.21, 0.89],
                      when: "afterChildren",
                      staggerChildren: 0.07
                    }
                  }
                }}
              >
                <SmallLogo alt="logo" src={LogoIcon} />
                <CloseButton
                  icon="pi pi-times"
                  className="p-button-rounded"
                  onClick={() => {
                    setMenuVisible(false)
                  }}
                />
                {PrimaryRoutes.map(({ path, label }, index) => (
                  <NavItemMobile
                    key={index}
                    selected={path === location.pathname}
                    onClick={() => {
                      navigate(path)
                      setMenuVisible(false)
                    }}
                    variants={{
                      visible: { opacity: 1, y: 0 },
                      hidden: { opacity: 0, y: 0 }
                      // hidden: { opacity: 0, y: -30 }
                    }}
                  >
                    {label}
                  </NavItemMobile>
                ))}
              </NavMobile>
            )}
          </AnimatePresence>
        </>
      ) : (
        <Nav>
          {PrimaryRoutes.map(({ path, label }, index) => (
            <NavItem
              key={index}
              to={path}
              active={location.pathname === path ? 1 : 0}
            >
              <div>{label}</div>
            </NavItem>
          ))}
        </Nav>
      )}
    </Holder>
  )
}

export default Navbar

const Holder = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1.6vh 1.8vw 0 1.8vw;
  transition: all 0.2s ease-out;
  @media ${device.pad} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1em;
    padding: 1.5em 1em;
  }
`
const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`

const Logo = styled.a`
  > img {
    width: 13em;
  }
`

const SmallLogo = styled.img`
  padding-left: 7vw;
  width: 35vw;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 1.3rem;
  right: 1rem;
  z-index: 1;
  background-color: white;
  span {
    color: #1b524e;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
`
const NavItem = styled(Link)`
  min-width: max-content;
  color: ${props => (props.active ? "#122945" : "#7b8694")};
  margin-left: 80px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  transition: all 300ms ease;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
  }
  &:hover {
    color: #122945;
  }
`
const NavMobile = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5em;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 30%;
  bottom: 0;
  padding: 2em 1em 2em 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`
const NavItemMobile = styled(motion.div)`
  color: ${({ selected }) => (selected ? "#144A68" : "#7B8694")};
  font-weight: ${({ selected }) => (selected ? 700 : 100)};
  font-size: 4.5vw;
  cursor: pointer;
  width: 100%;
  padding: 2vw 2vw 2vw 7vw;
  text-align: left;
  border-left: ${({ selected }) =>
    selected ? "5px solid #4B9679" : "5px solid #ffffff00"};
  transition: all 300ms ease-out;
`
