import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"

import cytoscape from "cytoscape"
// eslint-disable-next-line
import dagre from "cytoscape-dagre"
// eslint-disable-next-line
import cola from "cytoscape-cola"
// eslint-disable-next-line
import avsdf from "cytoscape-avsdf"
// eslint-disable-next-line
import euler from "cytoscape-euler"
// eslint-disable-next-line
import spread from "cytoscape-spread"
// eslint-disable-next-line
import d3Force from "cytoscape-d3-force"
// eslint-disable-next-line
import fcose from "cytoscape-fcose"
// eslint-disable-next-line
import springy from "cytoscape-springy"
// eslint-disable-next-line
import coseBilkent from "cytoscape-cose-bilkent"
// eslint-disable-next-line
import cise from "cytoscape-cise"

import { useStore } from "state/store"
import Loader from "components/Loader"

// cytoscape.use(dagre)
// cytoscape.use(spread)
// cytoscape.use(cise)
// cytoscape.use(fcose)
cytoscape.use(d3Force)

export default function Graph() {
  const setSelectedNodeId = useStore(state => state.setSelectedNodeId)
  const graphData = useStore(state => state.graphData)
  const currentLanguage = useStore(state => state.currentLanguage)
  const [isReady, setIsReady] = useState(true)

  const boardRef = useRef(null)
  useEffect(() => {
    let cy = cytoscape({
      container: boardRef.current,
      boxSelectionEnabled: false,
      userZoomingEnabled: true,
      zoomingEnabled: true,
      autounselectify: true,
      autoungrabify: false,
      minZoom: 0.1,
      maxZoom: 5,
      zoom: 1,
      wheelSensitivity: 0.1,
      style: cytoscape
        .stylesheet()
        .selector("node")
        .css({
          height: function (ele) {
            return ele.data("size")
          },
          width: function (ele) {
            return ele.data("size")
          },
          "background-fit": "cover cover",
          "background-color": "#fafafa",
          "background-opacity": "1",
          "background-image": function (ele) {
            return ele.data("icon")
          },
          "background-width": function (ele) {
            return ele.data("size")
          },
          "background-height": function (ele) {
            return ele.data("size")
          },
          "border-color": "#000",
          "border-width": 0,
          "border-opacity": 0.7,
          label: function (ele) {
            return ele.data(currentLanguage === "en" ? "label_en" : "label_ar")
          },
          "text-valign": "bottom",
          "text-margin-y": 15,
          "text-wrap": "wrap",
          "text-max-width": 120,
          "font-size": 12,
          color: "#000000",
          padding: 10,
          "shadow-blur": 20,
          "shadow-color": "#000",
          "shadow-offset-x": 10,
          "shadow-offset-y": 10,
          "shadow-opacity": 1
        })
        .selector("edge")
        .css({
          "curve-style": "bezier",
          "control-point-step-size": 20,
          "control-point-weight": 1,
          width: 1,
          "target-arrow-shape": "triangle",
          "line-color": "#55555522",
          "target-arrow-color": "#555",
          "font-size": 9,
          label: function (ele) {
            return ele.data(currentLanguage === "en" ? "label" : "label_ar")
          },
          "edge-text-rotation": "autorotate",
          "text-background-opacity": 0.8,
          "text-background-color": "#eee",
          "text-background-padding": 1
        }),
      elements: graphData,
      // //dagre
      // layout: {
      //   name: "dagre",
      //   // dagre algo options, uses default value on undefined
      //   nodeSep: undefined, // the separation between adjacent nodes in the same rank
      //   edgeSep: undefined, // the separation between adjacent edges in the same rank
      //   rankSep: undefined, // the separation between each rank in the layout
      //   rankDir: undefined, // 'TB' for top to bottom flow, 'LR' for left to right,
      //   ranker: undefined, // Type of algorithm to assign a rank to each node in the input graph. Possible values: 'network-simplex', 'tight-tree' or 'longest-path'
      //   minLen: function (edge) {
      //     return 4
      //   },
      //   // general layout options
      //   fit: true, // whether to fit to viewport
      //   padding: 30, // fit padding
      //   spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
      //   nodeDimensionsIncludeLabels: false, // whether labels should be included in determining the space used by a node
      //   animate: false, // whether to transition the node positions
      //   animationDuration: 500 // duration of animation in ms if enabled
      // }
      // //spread
      // layout: {
      //   name: "spread",
      //   animate: true, // Whether to show the layout as it's running
      //   ready: undefined, // Callback on layoutready
      //   stop: undefined, // Callback on layoutstop
      //   fit: true, // Reset viewport to fit default simulationBounds
      //   minDist: 20, // Minimum distance between nodes
      //   padding: 20, // Padding
      //   expandingFactor: -1.0, // If the network does not satisfy the minDist
      //   // criterium then it expands the network of this amount
      //   // If it is set to -1.0 the amount of expansion is automatically
      //   // calculated based on the minDist, the aspect ratio and the
      //   // number of nodes
      //   prelayout: { name: "cose" }, // Layout options for the first phase
      //   maxExpandIterations: 4, // Maximum number of expanding iterations
      //   boundingBox: undefined, // Constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
      //   randomize: false // Uses random initial node positions on true
      // }
      // //fcose
      // layout: {
      //   name: "fcose",
      //   // 'draft', 'default' or 'proof'
      //   // - "draft" only applies spectral layout
      //   // - "default" improves the quality with incremental layout (fast cooling rate)
      //   // - "proof" improves the quality with incremental layout (slow cooling rate)
      //   quality: "proof",
      //   // Use random node positions at beginning of layout
      //   // if this is set to false, then quality option must be "proof"
      //   randomize: true,
      //   // Whether or not to animate the layout
      //   animate: true,
      //   // Duration of animation in ms, if enabled
      //   animationDuration: 1000,
      //   // Easing of animation, if enabled
      //   animationEasing: undefined,
      //   // Fit the viewport to the repositioned nodes
      //   fit: true,
      //   // Padding around layout
      //   padding: 30,
      //   // Whether to include labels in node dimensions. Valid in "proof" quality
      //   nodeDimensionsIncludeLabels: false,
      //   // Whether or not simple nodes (non-compound nodes) are of uniform dimensions
      //   uniformNodeDimensions: false,
      //   // Whether to pack disconnected components - cytoscape-layout-utilities extension should be registered and initialized
      //   packComponents: true,
      //   // Layout step - all, transformed, enforced, cose - for debug purpose only
      //   step: "all",

      //   /* spectral layout options */

      //   // False for random, true for greedy sampling
      //   samplingType: true,
      //   // Sample size to construct distance matrix
      //   sampleSize: 25,
      //   // Separation amount between nodes
      //   nodeSeparation: 750,
      //   // Power iteration tolerance
      //   piTol: 0.0000001,

      //   /* incremental layout options */

      //   // Node repulsion (non overlapping) multiplier
      //   nodeRepulsion: node => 4500,
      //   // Ideal edge (non nested) length
      //   idealEdgeLength: edge => 50,
      //   // Divisor to compute edge forces
      //   edgeElasticity: edge => 0.45,
      //   // Nesting factor (multiplier) to compute ideal edge length for nested edges
      //   nestingFactor: 0.1,
      //   // Maximum number of iterations to perform - this is a suggested value and might be adjusted by the algorithm as required
      //   numIter: 2500,
      //   // For enabling tiling
      //   tile: true,
      //   // Represents the amount of the vertical space to put between the zero degree members during the tiling operation(can also be a function)
      //   tilingPaddingVertical: 10,
      //   // Represents the amount of the horizontal space to put between the zero degree members during the tiling operation(can also be a function)
      //   tilingPaddingHorizontal: 10,
      //   // Gravity force (constant)
      //   gravity: 0.25,
      //   // Gravity range (constant) for compounds
      //   gravityRangeCompound: 1.5,
      //   // Gravity force (constant) for compounds
      //   gravityCompound: 1.0,
      //   // Gravity range (constant)
      //   gravityRange: 3.8,
      //   // Initial cooling factor for incremental layout
      //   initialEnergyOnIncremental: 0.3,

      //   /* constraint options */

      //   // Fix desired nodes to predefined positions
      //   // [{nodeId: 'n1', position: {x: 100, y: 200}}, {...}]
      //   fixedNodeConstraint: undefined,
      //   // Align desired nodes in vertical/horizontal direction
      //   // {vertical: [['n1', 'n2'], [...]], horizontal: [['n2', 'n4'], [...]]}
      //   alignmentConstraint: undefined,
      //   // Place two nodes relatively in vertical/horizontal direction
      //   // [{top: 'n1', bottom: 'n2', gap: 100}, {left: 'n3', right: 'n4', gap: 75}, {...}]
      //   relativePlacementConstraint: undefined,

      //   /* layout event callbacks */
      //   ready: () => {}, // on layoutready
      //   stop: () => {} // on layoutstop
      // }
      // //cise
      // layout: {
      //   name: "cise",
      //   fit: true,
      //   padding: 40,
      //   animate: false,
      //   // collideStrength: 0.1,
      //   // sampleSize: 125,
      //   // piTol: 0.0001,
      //   // idealEdgeLength: edge => 100,
      //   // nodeRepulsion: node => 4500,
      //   // edgeElasticity: edge => 0.45,
      //   // maxRatioOfNodesInsideCircle: 0.1,
      //   idealInterClusterEdgeLengthCoefficient: 2.4,
      //   maxRatioOfNodesInsideCircle: 1,
      //   allowNodesInsideCircle: true,
      //   // gravity: 10.25,
      //   // gravityRange: 300.8,
      //   nodeRepulsion: 4500,
      //   // springCoeff: 10.45,
      //   nodeSeparation: 200
      // }
      //d3Force
      layout: {
        name: "d3-force",
        animate: true,
        maxSimulationTime: 25000,
        ungrabifyWhileSimulating: false,
        fixedAfterDragging: false,
        fit: true,
        padding: 40,
        linkId: function id(d) {
          return d.id
        },
        linkDistance: 400,
        manyBodyStrength: -8800,
        manyBodyDistanceMin: 150,
        manyBodyDistanceMax: Infinity,
        ready: function () {},
        stop: function () {
          setIsReady(true)
        },
        tick: function (progress) {
          if (isReady) {
            setIsReady(false)
          }
        },
        randomize: false,
        infinite: false,
        velocityDecay: 0.5, // sets the velocity decay factor to the specified number in the range [0,1]
        collideRadius: 100, // sets the radius accessor to the specified number or function
        collideStrength: 0.8, // sets the force strength to the specified number in the range [0,1]
        collideIterations: 1
      }
    }) // cy init
    cy.on("tap", "node", function (event) {
      setSelectedNodeId(event.target.data("id"))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, currentLanguage])
  return (
    <Holder>
      <Loader visible={!isReady} />
      <GraphContainer ref={boardRef} />
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #eee;
`

const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
`
