import { LinkData, NodeData } from "./data"

const GraphData = {
  edges: [],
  nodes: []
}

// Nodes
const nodes = []
NodeData.forEach(d => {
  nodes.push({
    code: d.id,
    name_en: d.label_en,
    name_ar: d.label_ar,
    data: {
      id: d.id,
      label_en: d.label_en,
      label_ar: d.label_ar,
      shape: "circularImage",
      type: d.type,
      icon: d.icon,
      size: d.icon_size
    }
  })
})

// Links
const edges = []
LinkData.forEach(d => {
  edges.push({
    data: {
      id: d.id,
      source: d.parent,
      target: d.child,
      label: d.type,
      label_ar: d.type_ar
    }
  })
})

GraphData.edges = edges
GraphData.nodes = nodes

export { GraphData }
