import { useMemo, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { NodeData, LinkData } from "components/NetworkGraph/dataset/data"
import { SidebarContent } from "components/NetworkGraph/Sidebar/Sidebar"
import { getQueryString } from "utils"
import { MiddleContainer } from "components/Containers"
import { useStore } from "state/store"

export default function Share() {
  const location = useLocation()
  const currentLanguage = useStore(state => state.currentLanguage)
  const setCurrentLanguage = useStore(state => state.setCurrentLanguage)
  const setSelectedNodeId = useStore(state => state.setSelectedNodeId)

  const textAlign = currentLanguage === "ar" ? "right" : "left"

  const [nodeData, setNodeData] = useState(null)
  const [relatedData, setRelatedData] = useState({})

  const nodeId = useMemo(() => {
    const id = getQueryString(location.search, "entity") || ""
    return id
  }, [location])

  useEffect(() => {
    const lang = getQueryString(location.search, "language") || "en"
    setCurrentLanguage(lang)

    const node = getQueryString(location.search, "node") || null
    setSelectedNodeId(node)
  }, [location])

  useEffect(() => {
    // Node data
    const newNodeData = NodeData?.find(d => d["id"] === nodeId)
    setNodeData(newNodeData)

    // Related data
    const newRelatedData = {}
    const newLinkData = LinkData?.filter(
      d => d["parent"] === nodeId || d["child"] === nodeId
    )

    newLinkData.forEach(d => {
      const id = d["parent"] === nodeId ? d["child"] : d["parent"]
      const label = NodeData.find(d => d["id"] === id)[
        currentLanguage === "en" ? "label_en" : "label_ar"
      ]

      if (d["type"] in newRelatedData) {
        newRelatedData[d["type"]].push({
          id: id,
          label: label,
          text: d["text"]
        })
      } else {
        newRelatedData[d["type"]] = [
          {
            id: id,
            label: label,
            text: d["text"]
          }
        ]
      }
    })

    setRelatedData(newRelatedData)
  }, [nodeId, currentLanguage])

  return (
    <Holder>
      {nodeData && (
        <SidebarContent
          style={{ textAlign: textAlign }}
          nodeData={nodeData}
          relatedData={relatedData}
          lang={currentLanguage}
        />
      )}
    </Holder>
  )
}

const Holder = styled(MiddleContainer)`
  width: 100%;
  min-height: 70vh;
`
