import styled from "styled-components"
import { device } from "theme/device"

export const MiddleContainer = styled.div`
  min-height: 600px;
  min-width: 300px;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
  @media ${device.pad} {
    width: 90%;
  }
`

export const SideContainer = styled.div`
  min-height: 600px;
  min-width: 320px;
  padding: 2em 3vw 3vw 3vw;
  flex-grow: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`
