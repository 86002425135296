import React, { useState } from "react"
import styled from "styled-components"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Password } from "primereact/password"
import { classNames } from "primereact/utils"

import PageTransitionHolder from "components/PageTransitionHolder"

import { device } from "theme/device"
import { loginRequest } from "api/auth"

export default function Login() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState(false)

  const { mutate, isLoading } = useMutation(loginRequest, {
    onSuccess: data => {
      if (data) {
        navigate("/")
        localStorage.setItem("token", data)
      } else {
        setIsError(true)
      }
    }
  })

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validate: data => {
      let errors = {}

      if (!data.username) {
        errors.username = "Username is required."
      }

      if (!data.password) {
        errors.password = "Password is required."
      }

      return errors
    },
    onSubmit: data => {
      mutate(data)
    }
  })

  const isFormFieldValid = username =>
    !!(formik.touched[username] && formik.errors[username])

  const getFormErrorMessage = username => {
    return (
      isFormFieldValid(username) && (
        <small className="p-error">{formik.errors[username]}</small>
      )
    )
  }

  return (
    <PageTransitionHolder>
      <Holder>
        <Card>
          <h2 className="text-center pb-3">Sign in</h2>
          <h4 className="text-center">Log in to access the SCE Network Map.</h4>
          {isError && (
            <Message>
              That email/username and password combination didn't work. Try
              again.
            </Message>
          )}
          <form onSubmit={formik.handleSubmit} className="p-fluid mt-6">
            <div className="field">
              <span className="p-float-label">
                <InputText
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  autoFocus
                  className={classNames({
                    "p-invalid": isFormFieldValid("username")
                  })}
                />
                <label
                  htmlFor="username"
                  className={classNames({
                    "p-error": isFormFieldValid("username")
                  })}
                >
                  Username*
                </label>
              </span>
              {getFormErrorMessage("username")}
            </div>
            <div className="field mt-6">
              <span className="p-float-label">
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  toggleMask
                  feedback={false}
                  className={classNames({
                    "p-invalid": isFormFieldValid("password")
                  })}
                />
                <label
                  htmlFor="password"
                  className={classNames({
                    "p-error": isFormFieldValid("password")
                  })}
                >
                  Password*
                </label>
              </span>
              {getFormErrorMessage("password")}
            </div>
            <Button
              type="submit"
              label="Submit"
              className="mt-4"
              loading={isLoading}
            />
          </form>
        </Card>
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  min-height: 80vh;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  padding-top: 15vh;
`

const Card = styled.div`
  width: 400px;
  height: fit-content;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 3px;
  background-color: #fff;
  padding: 2em;
  @media ${device.mobileL} {
    width: 90%;
  }
  > h2 {
    color: #454545;
  }
  > h4 {
    color: #848484;
  }
`

const Message = styled.div`
  background-color: rgba(241, 111, 109, 0.1);
  border: solid 1px #f16f6d;
  font-size: 0.8em;
  line-height: 1.4;
  color: #c64442;
  width: 100%;
  border-radius: 3px;
  padding: 1em;
`
