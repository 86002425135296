import ThemeProvider from "./theme"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { QueryClientProvider, QueryClient } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Footer from "components/Footer"
import { PrimaryRoutes } from "dataset/routes"
import Content from "components/Content"
import Navbar from "components/Navbar"
import Share from "pages/Share"

const queryClient = new QueryClient()

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar />
          <Content>
            <Routes>
              {PrimaryRoutes.map(({ path, component }, index) => (
                <Route key={index} path={path} element={component} />
              ))}
              <Route path="/share" element={<Share />} />
            </Routes>
          </Content>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
