import { useEffect } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import i18next from "i18next"
import NetworkGraph from "components/NetworkGraph"
import Toolbar from "components/Toolbar"
import { getCurrentDate } from "utils/getCurrentDate"

export default function Networkmap() {
  const navigate = useNavigate()
  useEffect(() => {
    //Initialize the language
    i18next.changeLanguage("en")

    // //Verify if the user is logged
    // const token = localStorage.getItem("token")
    // const date = getCurrentDate()
    // if (token !== date) {
    //   navigate("/login")
    // }
  }, [])

  return (
    <Holder>
      <Toolbar />
      <NetworkGraph />
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`
